import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import branch from './branch/branch.reducer';
import language from './language/language.reducer';
import panel from './panel/panel.reducer';
import auth from './user/auth.reducer';

const rootReducer = combineReducers({
  auth,
  panel,
  branch,
  language
});

const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));

export default store;
